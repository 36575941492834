import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: '首页',
    component: () => import('../layout'),
    redirect:'/index',
    children:[
      {
        path: 'index',
        name: '首页',
        component: () => import('../views/index/index')
      },
      {
        path: 'userCenter',
        name: '个人中心',
        component: () => import('../views/userCenter/index')
      },
    ]
  },{
    path: '/login',
    name: 'loginview',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
  }
  /*,{
    name: '404',
    path: '/:catchAll(.*)',
    component: () => import(`@/views/404.vue`)
  }*/
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
