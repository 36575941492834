<template>


  <router-view/>
  <LoginInvalid v-model="LoginInvalidDiag" :dialogVisible="LoginInvalidDiag"></LoginInvalid>
</template>

<script setup>
import LoginInvalid from '@/components/LoginInvalid.vue'
import store from '@/store'
import { ref ,watch} from 'vue'
import { useRoute,useRouter } from 'vue-router'
import bindRoute from "@/router/permission";
const route=useRoute();
const router=useRouter();
const whitePath=['/login','/index','/']


watch(route,(to,from)=>{
  console.log("to_name==>>"+to.name)
  console.log("to_path==>>"+to.path)
  if(to==null||to.name==undefined||to.path==undefined){
    store.commit("RESET_TABS");
    router.push({path:"/index"});
  }
  /*let obj = {
    name: to.name,
    path: to.path
  }

  if(to.name==undefined){
    let menuList=store.getters.GET_MENULIST();
    menuList.forEach(item=>{
      let index = item.children.findIndex(e=>e.path==to.path);
      console.log(index)
      if(index>-1){
        let toName = item.children[index].name;
        console.log(toName)
        obj.name=toName;
      }
    })
    console.log("obj==>>"+JSON.stringify(obj))

  }
  if (whitePath.indexOf(to.path)===-1) {
    console.log("to.path="+to.path)
    console.log(JSON.stringify(store.state.editableTabs));
    store.commit("ADD_TABS", obj)
    store.state.editableTabsValue=obj.path;
    console.log(JSON.stringify(store.state.editableTabs));
  }*/

},{deep:true,immediate:true});


let LoginInvalidDiag = ref(true);
watch(
    ()=>{return store.state.LoginInvalidIsShow},
    (newVal,oldVal)=>{
      console.log(newVal);
      console.log(oldVal);
      LoginInvalidDiag.value=store.state.LoginInvalidIsShow;
      if(LoginInvalidDiag.value){//如果token失效，将要退出登录时清除缓存登录信息
        window.sessionStorage.clear();
      }
    },{deep:true,immediate:true});

/*import requestUtil from '@/util/request'
import store from '@/store'
import login from '@/views/loginview'

const handleLogin=async ()=>{
  let result=await requestUtil.get("test/login");
  let data=result.data;
  if(data.code=200){
    const token=data.token;
    console.log("登录成功：token="+token);
    store.commit('SET_TOKEN',token);
  }else{
    console.log("登录出错！");
  }
}
const handleUserList=async ()=>{
  let result=await requestUtil.get("test/userlist");
  let data=result.data;
  console.log(data);
  if(data.code=200){
    const userList=data.userList;
    console.log("用户列表信息：userList="+userList);
    console.log(userList);
  }else{
    console.log(data.msg);
  }
}*/

</script>

<style>
html,body,#app{
  height: 100%;
}

.app-container{
  padding:20px
}
/*tr .cell{
  font-size: 12px;
  font-weight: 900;
}*/
.el-table__header-wrapper th {
  font-weight: 700 !important;
  font-size: 12px;
  font-family:"Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", 微软雅黑, Arial, sans-serif !important;
  color: rgb(81, 90, 110) !important;

}
tbody .el-table__row{
  font-size: 12px;
  font-family:"Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", 微软雅黑, Arial, sans-serif !important;
  color: rgb(81, 90, 110) !important;
}
</style>
