// 引入axios
import axios from 'axios';
import store from '@/store';
import {useRouter} from 'vue-router'
const router=useRouter();

let baseUrl="https://cszng.com/share_printer/";
// let baseUrl="https://aprilcats.cn/share_printer/";
//let baseUrl="http://localhost:8082/share_printer/";
//let baseUrl="http://192.168.1.5:8082/share_printer/";
// 创建axios实例
const httpService = axios.create({
    // url前缀-'http:xxx.xxx'
    // baseURL: process.env.BASE_API, // 需自定义
    baseURL:baseUrl,
    // 请求超时时间
    timeout: 30000 // 需自定义
});

//添加请求和响应拦截器
// 添加请求拦截器
httpService.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    //config.headers.token=window.sessionStorage.getItem('token');
    //console.log("store="+store.getters.GET_TOKEN());
    config.headers.token=store.getters.GET_TOKEN()
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
httpService.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    return response;
}, function (error) {
    // 对响应错误做点什么
    let code = error.response.data.code;
    if(401==code){
        //store.commit("SET_LoginInvalidIsShow", true)
        store.state.LoginInvalidIsShow=true;
    }
    return Promise.reject(error);
});

/*网络请求部分*/

/*
 *  get请求
 *  url:请求地址
 *  params:参数
 * */
export function get(url, params = {}) {
    //let timestamp = new Date().getTime();  // 获取当前时间戳
    return new Promise((resolve, reject) => {
        httpService({
            url: url/*+"?timestamp="+timestamp*/,//避免ajax发送重复的请求
            method: 'get',
            params: params
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

/*
 *  post请求
 *  url:请求地址
 *  params:参数
 * */
export function post(url, params = {}) {
    //let timestamp = new Date().getTime();  // 获取当前时间戳
    return new Promise((resolve, reject) => {
        httpService({
            url: url/*+"?timestamp="+timestamp*/,
            method: 'post',
            //ContentType: "application/json",
            data: params
        }).then(response => {
            console.log(response)
            resolve(response);
        }).catch(error => {
            console.log(error)
            reject(error);
        });
    });
}

/*
 *  文件上传
 *  url:请求地址
 *  params:参数
 * */
export function fileUpload(url, params = {}) {
    return new Promise((resolve, reject) => {
        httpService({
            url: url,
            method: 'post',
            data: params,
            headers: { 'Content-Type': 'multipart/form-data' }
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}
/*
 *  文件下载
 *  url:请求地址
 *  params:参数
 * */
export function fileDownload(url, params = {}) {
    return new Promise((resolve, reject) => {
        httpService({
            url: url,
            method: 'post',
            data: params,
            headers: { 'Content-Type': 'application/json' },
            responseType: 'arraybuffer'
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

export function getServerUrl(){
    return baseUrl;
}

export default {
    get,
    post,
    fileUpload,
    fileDownload,
    getServerUrl,
    baseUrl,
    token: () => store && store.getters.GET_TOKEN()
}
