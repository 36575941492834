import { createStore } from 'vuex'
import router from '@/router'
import requestUtil from "@/util/request"

export default createStore({
  state: {
    hasRoutes:false,
    editableTabsValue:'/index',
    editableTabs:[],
    LoginInvalidIsShow:false
  },
  getters: {
    GET_TOKEN: (state) =>()=> {//返回一个函数时则每次都会调用
        //console.log("调用了getters方法");
        let token = sessionStorage.getItem("token");
        return token;
    },
    GET_MENULIST: (state) =>()=> {//返回一个函数时则每次都会调用
      //console.log("调用了getters方法");
      let menuList = JSON.parse(sessionStorage.getItem("menuList")) ;
      return menuList;
    },
    GET_USERINFO: (state) =>()=> {//返回一个函数时则每次都会调用
      //console.log("调用了getters方法");
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo")) ;
      return userInfo;
    },
    GET_PERMS: (state) =>()=> {//返回一个函数时则每次都会调用
      //console.log("调用了getters方法");
      let perms = JSON.parse(sessionStorage.getItem("perms")) ;
      return perms;
    },
    GET_HASROUTES:(state)=>()=>{
      let hasRoutes = sessionStorage.getItem("hasRoutes");
      if(null==hasRoutes){
        hasRoutes=false;
      }
      return hasRoutes;
    },
    GETcnPCAtreeAll:(state)=>()=>{
      let cnPCAtreeAll = sessionStorage.getItem("cnPCAtreeAll");
      return cnPCAtreeAll;
    }
  },
  mutations: {
    SET_TOKEN:(state,token)=>{
      sessionStorage.setItem("token",token);
    },
    SET_MENULIST:(state,menuList)=>{
      sessionStorage.setItem("menuList",JSON.stringify(menuList));
    },
    SET_USERINFO:(state,userInfo)=>{
      sessionStorage.setItem("userInfo",JSON.stringify(userInfo));
    },
    SET_PERMS:(state,perms)=>{
      sessionStorage.setItem("perms",JSON.stringify(perms));
    },
    SET_ROUTES_STATE:(state,hasRoutes)=>{
      //state.hasRoutes=hasRoutes;
      sessionStorage.setItem("hasRoutes",hasRoutes);
    },
    SETcnPCAtreeAll:(state,pcaTreeAll)=>{
      sessionStorage.setItem("cnPCAtreeAll",JSON.stringify(pcaTreeAll));
    },
    ADD_TABS:(state,tab)=>{
      //console.log(tab.name,tab.path);
      if(tab.path!="/index"){
        if(state.editableTabs.findIndex(e=>e.name===tab.path)===-1){
          state.editableTabs.push({
            title:tab.menuName,
            name:tab.path
          })
        }
        state.editableTabsValue=tab.path;
      }else{
        state.editableTabsValue="/index";
      }

    },
    RESET_TABS:(state)=>{
        state.editableTabsValue='/index';
        state.editableTabs=[]
    },
    SET_LoginInvalidIsShow:(state,flag)=>{
      state.LoginInvalidIsShow=flag;
    }
  },
  actions: {//用于异步操作
    // 安全退出
    logout(){
      window.sessionStorage.clear();
      router.replace("/login")
    },
    async getIP() {
      return await fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(data => data.ip)
    },
    async addSystemLog (context, { link, ip }) {
      console.log(link, 999)
      const data = {
          "name": context.getters.GET_USERINFO().username,
          link,
          ip,
          "type": "system"
      }
      requestUtil.post("system_logs/save", data)
    }
  },
  modules: {
  }
})
