<template>

  <el-dialog v-model="dialogVisible"  title="温馨提示！" width="30%" draggable @close="closeComfim(0)" >
    <p>登录已失效，请重新登录！</p>
    <p>点击确定返回登录页面！</p>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="closeComfim(1)">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script  setup>
import {defineProps, ref} from 'vue'
import store from "@/store";
import {useRouter} from "vue-router/dist/vue-router";
const router=useRouter();

let dialogVisible = ref(false) ;

const props=defineProps(
    {
      dialogVisible:{
        type:Boolean,
        default:false,
        required:true
      }
    }
)

const closeComfim = (flag)=>{
  dialogVisible.value = false;
  store.state.LoginInvalidIsShow=false;//也许并没有改过来
  //store.commit("SET_LoginInvalidIsShow", true)
  if(flag==0){//直接关闭
    return;
  }
  //router.push("login")
  router.replace("/login");
  return;
  //window.location.href="/#/login";
}

</script>
<style scoped>
p{
  font-size: 15px;
  margin-left: 50px;
  margin-bottom: 10px;
}
</style>